import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'sign-in',
  //   pathMatch: 'full'
  // },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule,), canActivate : [AuthGuardService] 
  },
  {
    path: 'patient-list',
    loadChildren: () => import('./patient-list/patient-list.module').then( m => m.PatientListPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'add-patient',
    loadChildren: () => import('./add-patient/add-patient.module').then( m => m.AddPatientPageModule)
  },
  {
    path: 'patient-details/:id1/:id',
    loadChildren: () => import('./patient-details/patient-details.module').then( m => m.PatientDetailsPageModule)
  },
  {
    path: 'add-diagnosis/:id',
    loadChildren: () => import('./add-diagnosis/add-diagnosis.module').then( m => m.AddDiagnosisPageModule)
  },
  {
    path: 'patient-history/:id',
    loadChildren: () => import('./patient-history/patient-history.module').then( m => m.PatientHistoryPageModule)
  },
  {
    path: 'patient-blood/:id',
    loadChildren: () => import('./patient-blood/patient-blood.module').then( m => m.PatientBloodPageModule)
  },
  {
    path: 'notes/:id',
    loadChildren: () => import('./notes/notes.module').then( m => m.NotesPageModule)
  },
  {
    path: 'supervision-notes/:id',
    loadChildren: () => import('./supervision-notes/supervision.module').then( m => m.SupervisionPageModule)
  },
  {
    path: 'schedule-appointment/:id',
    loadChildren: () => import('./schedule-appointment/schedule-appointment.module').then( m => m.ScheduleAppointmentPageModule)
  },
  {
    path: 'add-prescription/:id',
    loadChildren: () => import('./add-prescription/add-prescription.module').then( m => m.AddPrescriptionPageModule)
  },
  {
    path: 'medicine-modal',
    loadChildren: () => import('./modals/medicine-modal/medicine-modal.module').then( m => m.MedicineModalPageModule)
  },
  {
    path: 'edit-prescription/:id',
    loadChildren: () => import('./edit-prescription/edit-prescription.module').then( m => m.EditPrescriptionPageModule)
  },
  {
    path: 'edit-patient-blood/:id',
    loadChildren: () => import('./edit-patient-blood/edit-patient-blood.module').then( m => m.EditPatientBloodPageModule)
  },
  {
    path: 'add-doctor',
    loadChildren: () => import('./add-doctor/add-doctor.module').then( m => m.AddDoctorPageModule)
  },
  {
    path: 'doctors',
    loadChildren: () => import('./doctors/doctors.module').then( m => m.DoctorsPageModule)
  },
  {
    path: 'volunteer',
    loadChildren: () => import('./volunteer/volunteer.module').then( m => m.VolunteerPageModule)
  },
  {
    path: 'volunteer-add',
    loadChildren: () => import('./volunteer-add/volunteer-add.module').then( m => m.VolunteerAddPageModule)
  },
  {
    path: 'clinics',
    loadChildren: () => import('./clinics/clinics.module').then( m => m.ClinicsPageModule)
  },
  {
    path: 'add-clinic',
    loadChildren: () => import('./add-clinic/add-clinic.module').then( m => m.AddClinicPageModule)
  },
  {
    path: 'assign-clinic',
    loadChildren: () => import('./assign-clinic/assign-clinic.module').then( m => m.AssignClinicPageModule)
  },
  {
    path: 'assign-doctor-list',
    loadChildren: () => import('./assign-doctor-list/assign-doctor-list.module').then( m => m.AssignDoctorListPageModule)
  },
  {
    path: 'volunteer-list/:id',
    loadChildren: () => import('./volunteer-list/volunteer-list.module').then( m => m.VolunteerListPageModule)
  },
  {
    path: 'assign-volunteer',
    loadChildren: () => import('./assign-volunteer/assign-volunteer.module').then( m => m.AssignVolunteerPageModule)
  },
  {
    path: 'schedule-list',
    loadChildren: () => import('./schedule-list/schedule-list.module').then( m => m.ScheduleListPageModule)
  },
  {
    path: 'todays-appointment',
    loadChildren: () => import('./todays-appointment/todays-appointment.module').then( m => m.TodaysAppointmentPageModule)
  },
  {
    path: 'appointment-list',
    loadChildren: () => import('./appointment-list/appointment-list.module').then( m => m.AppointmentListPageModule)
  },
  {
    path: 'volunteer-appointment',
    loadChildren: () => import('./volunteer-appointment/volunteer-appointment.module').then( m => m.VolunteerAppointmentPageModule)
  },
  {
    path: 'clicians',
    loadChildren: () => import('./clicians/clicians.module').then( m => m.CliciansPageModule)
  },
  {
    path: 'clician-add',
    loadChildren: () => import('./clician-add/clician-add.module').then( m => m.ClicianAddPageModule)
  },
  {
    path: 'clinicians',
    loadChildren: () => import('./clinicians/clinicians.module').then( m => m.CliniciansPageModule)
  },
  {
    path: 'clinician-add',
    loadChildren: () => import('./clinician-add/clinician-add.module').then( m => m.ClinicianAddPageModule)
  },
  {
    path: 'view-note',
    loadChildren: () => import('./modals/view-note/view-note.module').then( m => m.ViewNotePageModule)
  },
  { 
    path: 'edit-note/:id',
    loadChildren: () => import('./edit-note/edit-note.module').then( m => m.EditNotePageModule)
  },
  {
    path: 'edit-supervision/:id',
    loadChildren: () => import('./edit-supervision/edit-note.module').then( m => m.EditSupervisionPageModule)
  },
  {
    path: 'notes-details',
    loadChildren: () => import('./notes-details/notes-details.module').then( m => m.NotesDetailsPageModule)
  },
  {
    path: 'patient-note-detail/:id',
    loadChildren: () => import('./patient-note-detail/patient-note-detail.module').then( m => m.PatientNoteDetailPageModule)
  },
  {
    path: 'send-to-doctor/:id',
    loadChildren: () => import('./send-to-doctor/send-to-doctor.module').then( m => m.SendToDoctorPageModule)
  },
  {
    path: 'doctor-patient-list',
    loadChildren: () => import('./doctor-patient-list/doctor-patient-list.module').then( m => m.DoctorPatientListPageModule)
  },
  {
    path: 'assign-volunteer-to-clinic',
    loadChildren: () => import('./assign-volunteer-to-clinic/assign-volunteer-to-clinic.module').then( m => m.AssignVolunteerToClinicPageModule)
  },
  {
    path: 'assign-volunteer-clinic',
    loadChildren: () => import('./assign-volunteer-clinic/assign-volunteer-clinic.module').then( m => m.AssignVolunteerClinicPageModule)
  },
  {
    path: 'add-supervisor-feedback/:id',
    loadChildren: () => import('./add-supervisor-feedback/add-supervisor-feedback.module').then( m => m.AddSupervisorFeedbackPageModule)
  },
  {
    path: 'update-supervisor-feedback/:id',
    loadChildren: () => import('./update-supervisor-feedback/update-supervisor-feedback.module').then( m => m.UpdateSupervisorFeedbackPageModule)
  },
  {
    path: 'reschedule-appointment/:id',
    loadChildren: () => import('./reschedule-appointment/reschedule-appointment.module').then( m => m.RescheduleAppointmentPageModule)
  },
  {
    path: 'patient-form',
    loadChildren: () => import('./patient-form/patient-form.module').then( m => m.PatientFormPageModule)
  },
  {
    path: 'change-status',
    loadChildren: () => import('./modals/change-status/change-status.module').then( m => m.ChangeStatusPageModule)
  },
  {
    path: 'volunteer-patient',
    loadChildren: () => import('./volunteer-patient/volunteer-patient.module').then( m => m.VolunteerPatientPageModule)
  },
  {
    path: 'upload-signature',
    loadChildren: () => import('./upload-signature/upload-signature.module').then( m => m.UploadSignaturePageModule)
  },
  {
    path: 'sent-list',
    loadChildren: () => import('./sent-list/sent-list.module').then( m => m.SentListPageModule)
  },
  {
    path: 'co-morbidity/:id',
    loadChildren: () => import('./co-morbidity/co-morbidity.module').then( m => m.CoMorbidityPageModule)
  },
  {
    path: 'edit-doctor/:id',
    loadChildren: () => import('./edit-doctor/edit-doctor.module').then( m => m.EditDoctorPageModule)
  },
  {
    path: 'edit-clinician/:id',
    loadChildren: () => import('./edit-clinician/edit-clinician.module').then( m => m.EditClinicianPageModule)
  },
  {
    path: 'profile-update-log',
    loadChildren: () => import('./profile-update-log/profile-update-log.module').then( m => m.ProfileUpdateLogPageModule)
  },
  {
    path: 'profile-log-detail/:id/:id2',
    loadChildren: () => import('./profile-log-detail/profile-log-detail.module').then( m => m.ProfileLogDetailPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'clinician-report',
    loadChildren: () => import('./clinician-report/clinician-report.module').then( m => m.ClinicianReportPageModule)
  },
  {
    path: 'clinician-wise-patient-list',
    loadChildren: () => import('./clinician-wise-patient-list/clinician-wise-patient-list.module').then( m => m.ClinicianWisePatientListPageModule)
  },
  {
    path: 'appointment-report',
    loadChildren: () => import('./appointment-report/appointment-report.module').then( m => m.AppointmentReportPageModule)
  },
  {
    path: 'doctor-appointment-report',
    loadChildren: () => import('./doctor-appointment-report/doctor-appointment-report.module').then( m => m.DoctorAppointmentReportPageModule)
  },
  {
    path: 'assigned-patient-list/:id',
    loadChildren: () => import('./assigned-patient-list/assigned-patient-list.module').then( m => m.AssignedPatientListPageModule)
  },
  {
    path: 'volunteer-appointment-list',
    loadChildren: () => import('./volunteer-appointment-list/volunteer-appointment-list.module').then( m => m.VolunteerAppointmentListPageModule)
  },
  {
    path: 'clinic-edit/:id',
    loadChildren: () => import('./clinic-edit/clinic-edit.module').then( m => m.ClinicEditPageModule)
  },
  {
    path: 'edit-expenses/:id',
    loadChildren: () => import('./edit-expenses/edit-expenses.module').then( m => m.EditExpensesPageModule)
  },
  {
    path: 'preview-prescription',
    loadChildren: () => import('./modal/preview-prescription/preview-prescription.module').then( m => m.PreviewPrescriptionPageModule)
  },
  {
    path: 'upload-doctor-signature/:id',
    loadChildren: () => import('./upload-doctor-signature/upload-doctor-signature.module').then( m => m.UploadDoctorSignaturePageModule)
  },
  {
    path: 'change-clinic/:id',
    loadChildren: () => import('./change-clinic/change-clinic.module').then( m => m.ChangeClinicPageModule)
  },
  {
    path: 'medicine-list',
    loadChildren: () => import('./medicine-list/medicine-list.module').then( m => m.MedicineListPageModule)
  },
  {
    path: 'update-medicine-modal',
    loadChildren: () => import('./modals/update-medicine-modal/update-medicine-modal.module').then( m => m.UpdateMedicineModalPageModule)
  },
  {
    path: 'view-upcoming-appointment',
    loadChildren: () => import('./modals/view-upcoming-appointment/view-upcoming-appointment.module').then( m => m.ViewUpcomingAppointmentPageModule)
  },
  {
    path: 'switch-clinic',
    loadChildren: () => import('./switch-clinic/switch-clinic.module').then( m => m.SwitchClinicPageModule)
  },
  {
    path: 'select-clinic',
    loadChildren: () => import('./modals/select-clinic/select-clinic.module').then( m => m.SelectClinicPageModule)
  },
  {
    path: 'add-remark',
    loadChildren: () => import('./modals/add-remark/add-remark.module').then( m => m.AddRemarkPageModule)
  },
  {
    path: 'add-duration',
    loadChildren: () => import('./modals/add-duration/add-duration.module').then( m => m.AddDurationPageModule)
  },
  {
    path: 'add-mhw',
    loadChildren: () => import('./add-mhw/add-mhw.module').then( m => m.AddMhwPageModule)
  },
  {
    path: 'mhw',
    loadChildren: () => import('./mhw/mhw.module').then( m => m.MhwPageModule)
  },
  {
    path: 'edit-mhw/:id',
    loadChildren: () => import('./edit-mhw/edit-mhw.module').then( m => m.EditMhwPageModule)
  },
  {
    path: 'reset-profile-password/:id',
    loadChildren: () => import('./reset-profile-password/reset-profile-password.module').then( m => m.ResetProfilePasswordPageModule)
  },
  {
    path: 'activity',
    loadChildren: () => import('./activity/activity.module').then( m => m.ActivityPageModule)
  },
  {
    path: 'clinician-list',
    loadChildren: () => import('./modals/clinician-list/clinician-list.module').then( m => m.ClinicianListPageModule)
  },
  {
    path: 'clinic-list',
    loadChildren: () => import('./modals/clinic-list/clinic-list.module').then( m => m.ClinicListPageModule)
  },
  {
    path: 'select-clinic-list',
    loadChildren: () => import('./select-clinic-list/select-clinic-list.module').then( m => m.SelectClinicListPageModule)
  },
  {
    path: 'patient-report',
    loadChildren: () => import('./reports/patient-report/patient-report.module').then( m => m.PatientReportPageModule)
  },
  {
    path: 'assign-mhw-to-clinician',
    loadChildren: () => import('./assign-mhw-to-clinician/assign-mhw-to-clinician.module').then( m => m.AssignMhwToClinicianPageModule)
  },
  {
    path: 'assign-mhw-list',
    loadChildren: () => import('./assign-mhw-list/assign-mhw-list.module').then( m => m.AssignMhwListPageModule)
  },
  {
    path: 'mhw-viw-activuty/:id/:data',
    loadChildren: () => import('./mhw-viw-activuty/mhw-viw-activuty.module').then( m => m.MhwViwActivutyPageModule)
  },
  {
    path: 'change-activity-status',
    loadChildren: () => import('./modals/change-activity-status/change-activity-status.module').then( m => m.ChangeActivityStatusPageModule)
  },
  {
    path: 'mhw-notification',
    loadChildren: () => import('./mhw-notification/mhw-notification.module').then( m => m.MhwNotificationPageModule)
  },
  {
    path: 'add-additional-information',
    loadChildren: () => import('./modals/add-additional-information/add-additional-information.module').then( m => m.AddAdditionalInformationPageModule)
  },
  {
    path: 'mhw-activity-report',
    loadChildren: () => import('./reports/mhw-activity-report/mhw-activity-report.module').then( m => m.MhwActivityReportPageModule)
  },
  {
    path: 'mhw-activity-list',
    loadChildren: () => import('./mhw-activity-list/mhw-activity-list.module').then( m => m.MhwActivityListPageModule)
  },
  {
    path: 'date-range-patient-report',
    loadChildren: () => import('./reports/date-range-patient-report/date-range-patient-report.module').then( m => m.DateRangePatientReportPageModule)
  },
  {
    path: 'login-tracking-report',
    loadChildren: () => import('./reports/login-tracking-report/login-tracking-report.module').then( m => m.LoginTrackingReportPageModule)
  },
  {
    path: 'clinic-report',
    loadChildren: () => import('./reports/clinic-report/clinic-report.module').then( m => m.ClinicReportPageModule)
  },
  {
    path: 'patient-diagnosis-report',
    loadChildren: () => import('./reports/patient-diagnosis-report/patient-diagnosis-report.module').then( m => m.PatientDiagnosisReportPageModule)
  },
  {
    path: 'diagnosis-list',
    loadChildren: () => import('./diagnosis-list/diagnosis-list.module').then( m => m.DiagnosisListPageModule)
  },
  {
    path: 'add-diagnosis-list',
    loadChildren: () => import('./add-diagnosis-list/add-diagnosis-list.module').then( m => m.AddDiagnosisListPageModule)
  },
  {
    path: 'patient-single-screen/:id',
    loadChildren: () => import('./patient-single-screen/patient-single-screen.module').then( m => m.PatientSingleScreenPageModule)
  },
  {
    path: 'year-count-report',
    loadChildren: () => import('./reports/year-count-report/year-count-report.module').then( m => m.YearCountReportPageModule)
  },
  {
    path: 'sub-admin',
    loadChildren: () => import('./sub-admin/sub-admin.module').then( m => m.SubAdminPageModule)
  },
  {
    path: 'subadmins',
    loadChildren: () => import('./subadmins/subadmins.module').then( m => m.SubadminsPageModule)
  },
  {
    path: 'activity-report-file',
    loadChildren: () => import('./activity-report-file/activity-report-file.module').then( m => m.ActivityReportFilePageModule)
  },
  {
    path: 'clinician-view-activity/:id/:data',
    loadChildren: () => import('./clinician-view-activity/clinician-view-activity.module').then( m => m.ClinicianViewActivityPageModule)
  },
  {
    path: 'mhw-activity-view/:id/:data',
    loadChildren: () => import('./mhw-activity-view/mhw-activity-view.module').then( m => m.MhwActivityViewPageModule)
  },
  {
    path: 'mhw-activity-view-by-clinician/:id/:data',
    loadChildren: () => import('./mhw-activity-view-by-clinician/mhw-activity-view-by-clinician.module').then( m => m.MhwActivityViewByClinicianPageModule)
  },
  {
    path: 'edit-activity/:id',
    loadChildren: () => import('./edit-activity/edit-activity.module').then( m => m.EditActivityPageModule)
  },
  {
    path: 'mhw-reporter-profile',
    loadChildren: () => import('./mhw-reporter-profile/mhw-reporter-profile.module').then( m => m.MhwReporterProfilePageModule)
  },
  {
    path: 'add-mhw-reporter',
    loadChildren: () => import('./add-mhw-reporter/add-mhw-reporter.module').then( m => m.AddMhwReporterPageModule)
  },
  {
    path: 'edit-mhw-reporter/:id',
    loadChildren: () => import('./edit-mhw-reporter/edit-mhw-reporter.module').then( m => m.EditMhwReporterPageModule)
  },
  {
    path: 'reporter-home',
    loadChildren: () => import('./reporter-home/reporter-home.module').then( m => m.ReporterHomePageModule)
  },
  {
    path: 'move-user',
    loadChildren: () => import('./move-user/move-user.module').then( m => m.MoveUserPageModule)
  },
  {
    path: 'remainder-contribution',
    loadChildren: () => import('./reports/remainder-contribution/remainder-contribution.module').then( m => m.RemainderContributionPageModule)
  },
  {
    path: 'mhw-patient-psi-entries',
    loadChildren: () => import('./reports/mhw-patient-psi-entries/mhw-patient-psi-entries.module').then( m => m.MhwPatientPSIEntriesPageModule)
  },
  {
    path: 'patient-explore',
    loadChildren: () => import('./modals/patient-explore/patient-explore.module').then( m => m.PatientExplorePageModule)
  },
  {
    path: 'district',
    loadChildren: () => import('./district/district.module').then( m => m.DistrictPageModule)
  },
  {
    path: 'state',
    loadChildren: () => import('./state/state.module').then( m => m.StatePageModule)
  },
  {
    path: 'district-form',
    loadChildren: () => import('./modals/district-form/district-form.module').then( m => m.DistrictFormPageModule)
  },
  {
    path: 'state-form',
    loadChildren: () => import('./modals/state-form/state-form.module').then( m => m.StateFormPageModule)
  },
  {
    path: 'leave-form',
    loadChildren: () => import('./leave-form/leave-form.module').then( m => m.LeaveFormPageModule)
  },
  {
    path: 'leave-report',
    loadChildren: () => import('./reports/leave-report/leave-report.module').then( m => m.LeaveReportPageModule)
  },
  {
    path: 'leave-application',
    loadChildren: () => import('./leave-application/leave-application.module').then( m => m.LeaveApplicationPageModule)
  },
  {
    path: 'leave-list',
    loadChildren: () => import('./leave-list/leave-list.module').then( m => m.LeaveListPageModule)
  },
  {
    path: 'change-leave-status',
    loadChildren: () => import('./modals/change-leave-status/change-leave-status.module').then( m => m.ChangeLeaveStatusPageModule)
  },
  {
    path: 'edit-sub-admin/:id',
    loadChildren: () => import('./edit-sub-admin/edit-sub-admin.module').then( m => m.EditSubAdminPageModule)
  },
  {
    path: 'office-admin',
    loadChildren: () => import('./office-admin/office-admin.module').then( m => m.OfficeAdminPageModule)
  },
  {
    path: 'office-admin-list',
    loadChildren: () => import('./office-admin-list/office-admin-list.module').then( m => m.OfficeAdminListPageModule)
  },
  {
    path: 'edit-office-admin/:id',
    loadChildren: () => import('./edit-office-admin/edit-office-admin.module').then( m => m.EditOfficeAdminPageModule)
  },
  {
    path: 'profile-view/:id',
    loadChildren: () => import('./profile-view/profile-view.module').then( m => m.ProfileViewPageModule)
  },
  {
    path: 'staff-reports',
    loadChildren: () => import('./reports/staff-reports/staff-reports.module').then( m => m.StaffReportsPageModule)
  },
  {
    path: 'leave-aprrover-manage',
    loadChildren: () => import('./leave-aprrover-manage/leave-aprrover-manage.module').then( m => m.LeaveAprroverManagePageModule)
  },
  {
    path: 'sdo',
    loadChildren: () => import('./sdo/sdo.module').then( m => m.SDOPageModule)
  },
  {
    path: 'add-sdo',
    loadChildren: () => import('./add-sdo/add-sdo.module').then( m => m.AddSDOPageModule)
  },
  {
    path: 'edit-sdo/:id',
    loadChildren: () => import('./edit-sdo/edit-sdo.module').then( m => m.EditSDOPageModule)
  },
  {
    path: 'sdo-activity',
    loadChildren: () => import('./sdo-activity/sdo-activity.module').then( m => m.SdoActivityPageModule)
  },
  {
    path: 'sdo-approver',
    loadChildren: () => import('./modals/sdo-approver/sdo-approver.module').then( m => m.SdoApproverPageModule)
  },
  {
    path: 'sdo-psi-entries',
    loadChildren: () => import('./sdo-psi-entries/sdo-psi-entries.module').then( m => m.SdoPsiEntriesPageModule)
  },
  {
    path: 'sdo-notification',
    loadChildren: () => import('./sdo-notification/sdo-notification.module').then( m => m.SdoNotificationPageModule)
  },
  {
    path: 'sdo-additional-information',
    loadChildren: () => import('./modals/sdo-additional-information/sdo-additional-information.module').then( m => m.SdoAdditionalInformationPageModule)
  },
  {
    path: 'sdo-activity-report-file',
    loadChildren: () => import('./sdo-activity-report-file/sdo-activity-report-file.module').then( m => m.SdoActivityReportFilePageModule)
  },
  {
    path: 'sdo-activity-view/:id/:data',
    loadChildren: () => import('./sdo-activity-view/sdo-activity-view.module').then( m => m.SdoActivityViewPageModule)
  },
  {
    path: 'sdo-activity-view-by-approver/:id/:data',
    loadChildren: () => import('./sdo-activity-view-by-approver/sdo-activity-view-by-approver.module').then( m => m.SdoActivityViewByApproverPageModule)
  },
  {
    path: 'edit-sdo-activity/:id',
    loadChildren: () => import('./edit-sdo-activity/edit-sdo-activity.module').then( m => m.EditSdoActivityPageModule)
  },
  {
    path: 'sdo-approver',
    loadChildren: () => import('./sdo-approver/sdo-approver.module').then( m => m.SDOApproverPageModule)
  },
  {
    path: 'manage-sdo-approver',
    loadChildren: () => import('./manage-sdo-approver/manage-sdo-approver.module').then( m => m.ManageSdoApproverPageModule)
  },
  {
    path: 'sdo-activity-list',
    loadChildren: () => import('./sdo-activity-list/sdo-activity-list.module').then( m => m.SdoActivityListPageModule)
  },
  {
    path: 'approver-view-activity/:id/:data',
    loadChildren: () => import('./approver-view-activity/approver-view-activity.module').then( m => m.ApproverViewActivityPageModule)
  },
  {
    path: 'change-sdo-activity-status',
    loadChildren: () => import('./modals/change-sdo-activity-status/change-sdo-activity-status.module').then( m => m.ChangeSdoActivityStatusPageModule)
  },
  {
    path: 'sdo-activity-report',
    loadChildren: () => import('./reports/sdo-activity-report/sdo-activity-report.module').then( m => m.SdoActivityReportPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./request/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'delete-request/:data',
    loadChildren: () => import('./request/delete-request/delete-request.module').then( m => m.DeleteRequestPageModule)
  },
  {
    path: 'feedback-list',
    loadChildren: () => import('./feedback-list/feedback-list.module').then( m => m.FeedbackListPageModule)
  },
  {
    path: 'request-modal',
    loadChildren: () => import('./modals/request-modal/request-modal.module').then( m => m.RequestModalPageModule)
  },
  {
    path: 'user-request-list',
    loadChildren: () => import('./user-request-list/user-request-list.module').then( m => m.UserRequestListPageModule)
  },
  {
    path: 'user-request',
    loadChildren: () => import('./modals/user-request/user-request.module').then( m => m.UserRequestPageModule)
  },
  {
    path: 'outside-info',
    loadChildren: () => import('./outside-info/outside-info.module').then( m => m.OutsideInfoPageModule)
  },
  {
    path: 'delete-record',
    loadChildren: () => import('./request/delete-record/delete-record.module').then( m => m.DeleteRecordPageModule)
  },
  {
    path: 'change-records',
    loadChildren: () => import('./request/change-records/change-records.module').then( m => m.ChangeRecordsPageModule)
  },
  {
    path: 'add-records',
    loadChildren: () => import('./request/add-records/add-records.module').then( m => m.AddRecordsPageModule)
  },
  {
    path: 'change-request/:data',
    loadChildren: () => import('./request/change-request/change-request.module').then( m => m.ChangeRequestPageModule)
  },
  {
    path: 'email-list',
    loadChildren: () => import('./request/email-list/email-list.module').then( m => m.EmailListPageModule)
  },
  {
    path: 'close-expense',
    loadChildren: () => import('./close-expense/close-expense.module').then( m => m.CloseExpensePageModule)
  },
  {
    path: 'close-activity',
    loadChildren: () => import('./activity-last/activity-last.module').then( m => m.CloseActivityePageModule)
  },
  {
    path: 'digital-features',
    loadChildren: () => import('./digital-features/digital-features.module').then( m => m.DigitalFeaturesPageModule)
  },
  {
    path: 'ideas',
    loadChildren: () => import('./request/ideas/ideas.module').then( m => m.IdeasPageModule)
  },
  {
    path: 'ideas-list',
    loadChildren: () => import('./request/ideas-list/ideas-list.module').then( m => m.IdeasListPageModule)
  },
  {
    path: 'ideas-list-admin',
    loadChildren: () => import('./request/ideas-list-admin/ideas-list-admin.module').then( m => m.IdeasListAdminPageModule)
  },
  {
    path: 'patient-prescription-report',
    loadChildren: () => import('./reports/patient-prescription-report/patient-prescription-report.module').then( m => m.PatientPrescriptionReportPageModule)
  },
  {
    path: 'assign-roles/:data',
    loadChildren: () => import('./assign-roles/assign-roles.module').then( m => m.AssignRolesPageModule)
  },
  {
    path: 'assigned-roles-list/:data',
    loadChildren: () => import('./assigned-roles-list/assigned-roles-list.module').then( m => m.AssignedRolesListPageModule)
  },
  {
    path: 'patient-activity-report',
    loadChildren: () => import('./reports/patient-activity-report/patient-activity-report.module').then( m => m.PatientActivityReportPageModule)
  },
  {
    path: 'digital-features-form/:id',
    loadChildren: () => import('./digital-features-form/digital-features-form.module').then( m => m.DigitalFeaturesFormPageModule)
  },
  {
    path: 'multiple-role-user',
    loadChildren: () => import('./multiple-role-user/multiple-role-user.module').then( m => m.MultipleRoleUserPageModule)
  },
  {
    path: 'clinicain-date-range-report',
    loadChildren: () => import('./reports/clinicain-date-range-report/clinicain-date-range-report.module').then( m => m.ClinicainDateRangeReportPageModule)
  },
  {
    path: 'daily-activity',
    loadChildren: () => import('./activities/daily-activity/daily-activity.module').then( m => m.DailyActivityPageModule)
  },
  {
    path: 'clinician-doctor-activity-report/:data',
    loadChildren: () => import('./reports/clinician-doctor-activity-report/clinician-doctor-activity-report.module').then( m => m.ClinicianDoctorActivityReportPageModule)
  },
  {
    path: 'expenses',
    loadChildren: () => import('./expenses/expenses.module').then( m => m.ExpensesPageModule)
  },
  {
    path: 'expense-report',
    loadChildren: () => import('./reports/expense-report/expense-report.module').then( m => m.ExpenseReportPageModule)
  },
  {
    path: 'expense-all-staff-report',
    loadChildren: () => import('./reports/expense-all-staff-report/expense-all-staff-report.module').then( m => m.ExpenseAllStaffReportPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [
    AuthGuardService
  ]
})
export class AppRoutingModule {}
