import { Component, OnInit } from '@angular/core';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { EventService } from './service/event.service';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { AuthGuardService } from './auth-guard.service';
import { HomePage } from './home/home.page';
import { HttpClient } from '@angular/common/http';
import { Geolocation, GeolocationOptions, Geoposition, PositionError } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
declare var google: any;
declare var navigator: any;
declare var window: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages;
  public showLogout = true;
  public punchInBtn = true;
  public punchOutBtn = false;
  options: GeolocationOptions;
  email: any = {};
  profile_image: any = {};
  username: any = {};
  user_id: any = {};
  clinic_id: any = {};
  clinic_name: any = '';
  role: any = {};
  baseURI: string = environment.app_url;
  baseURI2: string = environment.main_url;
  public icon = 'power';
  public backButtonSubscription: any;
  nativeStorage: any;
  rootPage: any;
  storage: any;
  private loggedIn: boolean = false;
  notification_count: any = 0;
  showLevel1 = null;
  showLevel2 = null;
  showLevel3 = null;
  latitude: any = 0; //latitude
  longitude: any = 0; //longitude
  address: any = '';
  nativeGeocoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };

  subMenuOpen: { [key: string]: boolean } = {
    homeSubmenu: false,
    aboutSubmenu: false,
  };

  async toggleSubMenu(subMenu: string) {
    let subMenuArray = ['staffSubmenu', 'approverSubmenu', 'roleSubmenu', 'expenseSubmenu', 'leaveSubmenu', 'requestSubmenu', 'makeSubmenu'];
    for(let i = 0; i < subMenuArray.length; i++){
      this.subMenuOpen[subMenuArray[i]] = false;
    }

    let menuArray = ['homeSubmenu', 'recordSubmenu', 'clinicSubmenu', 'activitySubmenu', 'mappingSubmenu', 'areaSubmenu', 'reportSubmenu'];
    for(let i = 0; i < menuArray.length; i++){
      if(menuArray[i] == subMenu){
        this.subMenuOpen[subMenu] = !this.subMenuOpen[subMenu];
      }else{
        this.subMenuOpen[menuArray[i]] = false;
      }
    } 
  }

  async togglMenu(subMenu: string) {
    let menuArray = ['staffSubmenu', 'approverSubmenu', 'roleSubmenu', 'expenseSubmenu', 'leaveSubmenu', 'requestSubmenu', 'makeSubmenu'];
    for(let i = 0; i < menuArray.length; i++){
      if(menuArray[i] == subMenu){
        this.subMenuOpen[subMenu] = !this.subMenuOpen[subMenu];
      }else{
        this.subMenuOpen[menuArray[i]] = false;
      }
    } 
  }

  togglMenulast(subMenu: string){
    this.subMenuOpen[subMenu] = !this.subMenuOpen[subMenu];
  }

  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private event: EventService,
    private location: Location,
    private router: Router,
    public navCtrl: NavController,
    private authGuardService: AuthGuardService,
    public http: HttpClient,
    public menuCntrl: MenuController,
    private nativeGeocoder: NativeGeocoder,
    private geolocation: Geolocation
  ) {
    this.getProfileNotificationCount();
    this.getCurrentCoordinates();
    this.initializeApp();
    var self = this;
    setInterval(function () {
      //   //this.vibrate();
      self.getCurrentCoordinates();
    }, 300000);
    setInterval(function () {
      //   //this.vibrate();
      self.checkPunchIn();
    }, 5000);

    this.email = localStorage.getItem('email');
    this.profile_image = localStorage.getItem('profile_image');
    this.username = localStorage.getItem('username');
    this.user_id = localStorage.getItem('user_id');
    this.role = localStorage.getItem('role');
    this.clinic_id = localStorage.getItem('clinic_id');
    if (this.clinic_id != 0) {
      this.getClinicNameById(this.clinic_id);
    }
    if ((localStorage.getItem('role')) == 'volunteer') {
      this.getSideBar('volunteer');
    }
    else if ((localStorage.getItem('role')) == 'clinician') {
      this.getSideBar('clinician');

    } else if ((localStorage.getItem('role')) == 'doctor') {

      this.getSideBar('doctor');
    }
    else if ((localStorage.getItem('role')) == 'clinicadmin') {
      this.getSideBar('clinicadmin');
    }
    else if ((localStorage.getItem('role')) == 'mhw') {
      this.getSideBar('mhw');
    } else if ((localStorage.getItem('role')) == 'mhw_reporter') {
      this.getSideBar('mhw_reporter');
    } else if ((localStorage.getItem('role')) == 'office_admin') {
      this.getSideBar('office_admin');
    } else if ((localStorage.getItem('role')) == 'sdo') {
      this.getSideBar('sdo');
    }
  }

  staff() {
    window.open('https://docs.google.com/spreadsheets/d/1QDakcKTSx3Ap3H-K8JeqNIrebBOhfGHi_vsPsmMLj04/edit?usp=sharing', '_system');
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString("#93b82e");
      this.splashScreen.hide();
      this.authGuardService.authState.subscribe(state => {
        if (state) {
          if ((localStorage.getItem('role')) == 'volunteer') {
            this.router.navigate(['/volunteer-patient']);
          } if ((localStorage.getItem('role')) == 'doctor') {
            //this.router.navigate(['/doctor-patient-list']);
            this.router.navigate(['/daily-activity']);
          } if (localStorage.getItem('role') == 'clinicadmin') {
            this.router.navigate(['/notification']);
          } if (localStorage.getItem('role') == 'mhw') {
            this.router.navigate(['/activity']);
          } if (localStorage.getItem('role') == 'mhw_reporter') {
            this.router.navigate(['/mhw-activity-report']);
          } if (localStorage.getItem('role') == 'office_admin') {
            this.router.navigate(['/leave-form']);
          } if (localStorage.getItem('role') == 'sdo') {
            this.router.navigate(['/sdo-activity']);
          }
          if (localStorage.getItem('role') == 'clinician') {
            this.router.navigate(['/daily-activity']);
          }
          this.menuCntrl.enable(true);

        } else {
          this.menuCntrl.enable(false);
          this.showLogout = false;
          this.router.navigate(['/sign-in']);
        }
      });

    });


    this.event.subscribe('user:check_notification', (data: any) => {
      this.getProfileNotificationCount();
    })

    /*Service For User Login*/
    this.event.subscribe('userprofile:created', (data) => {
      localStorage.setItem('email', data['email']);
      this.email = localStorage.getItem('email');
      this.user_id = data['user_id'];
      this.username = data['username'];
      this.role = localStorage.getItem('role');
      localStorage.setItem('profile_image', data['profile_image']);
      this.profile_image = localStorage.getItem('profile_image');
    });
    /*Service For User Login*/
    this.event.subscribe('userclinic:created', (data) => {
      this.clinic_id = data;
      if (this.clinic_id != 0) {
        this.getClinicNameById(this.clinic_id);
      }
    });
    this.event.subscribe('user:login', (data: any) => {
      this.email = localStorage.getItem('email');
      this.username = localStorage.getItem('username');
      this.user_id = localStorage.getItem('user_id');
      this.role = localStorage.getItem('role');
      this.profile_image = localStorage.getItem('profile_image');
      this.clinic_id = localStorage.getItem('clinic_id');
      if (this.clinic_id != 0) {
        this.getClinicNameById(this.clinic_id);
      }
      this.selectedIndex = 0;
      this.menuCntrl.enable(true);
      this.showLogout = true;
      //this.showLogout = true; = true;

      if ((localStorage.getItem('role')) == 'volunteer') {
        this.getSideBar('volunteer');
      }
      else if ((localStorage.getItem('role')) == 'clinician') {
        this.getSideBar('clinician');

      } else if ((localStorage.getItem('role')) == 'doctor') {

        this.getSideBar('doctor');
      }
      else if ((localStorage.getItem('role')) == 'clinicadmin') {
        this.getSideBar('clinicadmin');
      }
      else if ((localStorage.getItem('role')) == 'mhw') {
        this.getSideBar('mhw');
      } else if ((localStorage.getItem('role')) == 'mhw_reporter') {
        this.getSideBar('mhw_reporter');
      } else if ((localStorage.getItem('role')) == 'office_admin') {
        this.getSideBar('office_admin');
      } else if ((localStorage.getItem('role')) == 'sdo') {
        this.getSideBar('sdo');
      }

      //console.log('Welcome', data.user, 'at', data.time);
    });
  }

  punchIn() {
    var formData: any = new FormData();
    formData.append("key", 'add_location');
    formData.append("lat", this.latitude);
    formData.append("lon", this.longitude);
    formData.append("address", this.address);
    formData.append("clinic_id", this.clinic_id);
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("activity", 'Punch In');
    this.http.post(this.baseURI + 'manage-user.php', formData).subscribe((res) => {
      if (res['status'] == 1) {
        this.punchInBtn = false;
        this.punchOutBtn = true;
      } else if (res['status'] == 2) {
        alert('You already punched in and punched out today.');
      }

    });
  }
  punchOut() {
    var formData: any = new FormData();
    formData.append("key", 'update_location');
    formData.append("lat", this.latitude);
    formData.append("lon", this.longitude);
    formData.append("address", this.address);
    formData.append("clinic_id", this.clinic_id);
    formData.append("user_id", localStorage.getItem('user_id'));
    formData.append("activity", 'Punch Out');
    this.http.post(this.baseURI + 'manage-user.php', formData).subscribe((res) => {
      if (res['status'] == 1) {
        this.punchInBtn = true;
        this.punchOutBtn = false;
      }

    });
  }

  checkPunchIn() {
    var formData: any = new FormData();
    formData.append("key", 'check_user_by_current_id');
    formData.append("clinic_id", this.clinic_id);
    formData.append("id", localStorage.getItem('user_id'));
    this.http.post(this.baseURI + 'manage-user.php', formData).subscribe((res) => {
      if (res['status'] == 1) {
        this.punchInBtn = true;
        this.punchOutBtn = false;
      } else {
        this.punchInBtn = false;
        this.punchOutBtn = true;
      }

    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
  ngAfterViewInit() {

    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      // add logic here if you want to ask for a popup before exiting
      if (this.router.url === '/home') {
        //navigator.app.exitApp();
      } else if (this.router.url === '/sign-in') {
        navigator.app.exitApp();
      } else {
        this.location.back();
      }
    });
  }

  getProfileNotificationCount() {
    if (this.role == 'clinicadmin') {
      var formData: any = new FormData();
      formData.append("key", 'get_approval_notification_count');
      this.http.post(this.baseURI + 'profile-approval-request.php', formData).subscribe((res) => {
        this.notification_count = res['notification_count'];
      });
    }

  }
  toggleLevel1(idx) {
    if (this.isLevel1Shown(idx)) {
      this.showLevel1 = null;
    } else {
      this.showLevel1 = idx;
    }
  }
  isLevel1Shown(idx) {
    return this.showLevel1 === idx;
  }

  toggleLevel2(idx) {
    if (this.isLevel2Shown(idx)) {
      this.showLevel2 = null;
    } else {
      this.showLevel1 = idx;
      this.showLevel2 = idx;
    }
    //console.log(this.showLevel2,'--',this.showLevel1)
  }
  isLevel2Shown(idx) {
    //console.log(this.showLevel2,'--',idx);
    return this.showLevel2 === idx;
  }

  /*   toggleLevel3(idx: string) {
      if (this.isLevel3Shown(idx)) {
        this.showLevel3 = null;
      } else {
        this.showLevel2 = idx;
        this.showLevel3 = idx;
      }
    }
    isLevel3Shown(idx: string) {
      return this.showLevel3 === idx;
    } */

  getSideBar(role) {
    console.log(role);
    if (role == 'volunteer') {
      this.appPages = [

        {
          title: 'Volunteer Appointment',
          url: 'volunteer-patient',
          icon: 'list'
        },
        {
          title: 'Send To Clinic Patient',
          url: 'volunteer-appointment-list',
          icon: 'list'
        },
        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Staff Report',
          url: 'staff-reports',
          icon: 'people'
        },
        {
          title: 'Follow Up Notes',
          url: 'notes-details',
          icon: 'list'
        },

        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },



      ];
    }
    else if (role == 'clinician') {
      this.appPages = [
        {
          title: 'Home',
          url: 'daily-activity',
          icon: 'home'
        },
        {
          title: 'Todays Appointment',
          url: 'todays-appointment',
          icon: 'list'
        },
        {
          title: 'Patient list',
          url: 'patient-list',
          icon: 'document-text'
        },
        {
          title: 'Patient Deactivation',
          url: 'patient-activity-report',
          icon: 'list'
        },
        {
          title: 'Switch Clinic',
          url: 'switch-clinic',
          icon: 'toggle'
        },
        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Activity & Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Activity Entry',
              url: 'daily-activity',
              icon: 'document-text'
            },
            {
              title: 'Activity Report',
              url: 'clinician-doctor-activity-report/clinician',
              icon: 'list'
            },
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },
        {
          title: 'New Patient',
          url: 'patient-form',
          icon: 'person'
        },
        {
          title: 'MHW Activity Approval',
          url: 'mhw-activity-list',
          icon: 'document-text'
        },
        {
          title: 'SDO Activity Approval',
          url: 'sdo-activity-list',
          icon: 'document-text'
        },
        {
          title: 'Staff Report',
          url: 'staff-reports',
          icon: 'people'
        },
        {
          title: 'Volunteer list',
          url: 'volunteer',
          icon: 'list'
        },
        {
          title: 'Assign Volunteer',
          url: 'assign-volunteer',
          icon: 'book'
        },
        // {
        //   title: 'Schedule Appointment',
        //   url: 'schedule-list',
        //   icon: 'newspaper'
        // },
        {
          title: 'Appointments',
          url: 'appointment-list',
          icon: 'list'
        },
        // {
        //   title: 'Todays List',
        //   url: 'todays-appointment',
        //   icon: 'list'
        // },
        {
          title: 'Follow Up Notes',
          url: 'notes-details',
          icon: 'list'
        },
        // {
        //   title: 'Volunteer Appointment',
        //   url: 'volunteer-appointment',
        //   icon: 'newspaper'
        // },
        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },


      ];

    } else if (role == 'doctor') {

      this.appPages = [
        {
          title: 'Home',
          url: 'daily-activity',
          icon: 'home'
        },
        {
          title: 'Prescription Approval',
          url: 'doctor-patient-list',
          icon: 'list'
        },
        {
          title: 'Switch Clinic',
          url: 'switch-clinic',
          icon: 'toggle'
        },
        {
          title: 'Activity & Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Activity Entry',
              url: 'daily-activity',
              icon: 'document-text'
            },
            {
              title: 'Activity Report',
              url: 'clinician-doctor-activity-report/doctor',
              icon: 'list'
            },
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Patient list',
          url: 'patient-list',
          icon: 'document-text'
        },
        {
          title: 'SDO Activity Approval',
          url: 'sdo-activity-list',
          icon: 'document-text'
        },
        {
          title: 'Staff Report',
          url: 'staff-reports',
          icon: 'people'
        },
        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Upload Signature',
          url: 'upload-signature',
          icon: 'document-text'
        },
        {
          title: 'Appointment Report',
          url: '/doctor-appointment-report',
          icon: 'list'
        },
        {
          title: 'Send list',
          url: 'sent-list',
          icon: 'send'
        },
        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },


      ];
    } else if (role == 'mhw') {

      this.appPages = [
        {
          title: 'Home',
          url: 'activity',
          icon: 'home'
        },
        {
          title: 'Switch Clinic',
          url: 'switch-clinic',
          icon: 'toggle'
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Patient list',
          url: 'patient-list',
          icon: 'document-text'
        },
        {
          title: 'Patient Encounter',
          url: 'mhw-patient-psi-entries',
          icon: 'document-text'
        },
        {
          title: 'Approval Status',
          url: 'mhw-notification',
          icon: 'notifications'
        },
        {
          title: 'Activity & Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Activity Entry',
              url: 'activity',
              icon: 'document-text'
            },
            {
              title: 'Activity Report',
              url: 'activity-report-file',
              icon: 'list'
            },
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Staff Report',
          url: 'staff-reports',
          icon: 'people'
        },
        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },


      ];
    }
    else if (role == 'mhw_reporter') {
      this.appPages = [

        {
          title: 'Home',
          url: 'mhw-activity-report',
          icon: 'home'
        },

        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },

        {
          title: 'SDO Activity Approval',
          url: 'sdo-activity-list',
          icon: 'document-text'
        },

        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Reports',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'Patient Date Range Report',
              url: 'date-range-patient-report',
              icon: 'person'
            },
            {
              title: 'MHW Activity Report',
              url: 'mhw-activity-report',
              icon: 'person'
            },
            {
              title: 'SDO Activity Report',
              url: 'sdo-activity-report',
              icon: 'person'
            },
            {
              title: 'Location Based Attendance Report',
              url: 'login-tracking-report',
              icon: 'location'
            },
            {
              title: 'Staff Report',
              url: 'staff-reports',
              icon: 'people'
            },
            {
              title: 'Clinician Daily Activity Report',
              url: 'clinician-doctor-activity-report/clinician',
              icon: 'newspaper',

            },
            {
              title: 'Doctor Daily Activity Report',
              url: 'clinician-doctor-activity-report/doctor',
              icon: 'newspaper',

            },
            {
              title: 'Staff Expense Report',
              url: 'expense-all-staff-report',
              icon: 'list',

            },
            {
              title: 'Leave Information Report',
              url: 'leave-report',
              icon: 'location'
            }
          ]
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },


      ];
    }
    else if (role == 'clinicadmin') {
      this.appPages = [
        {
          title: 'Admin Action',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Mcare',
              url: 'feedback-list',
              icon: 'document-text'
            },
            {
              title: 'Email',
              url: 'email-list',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list-admin',
              icon: 'document-text'
            },
            {
              title: 'Close Expense',
              url: 'close-expense',
              icon: 'document-text'
            },
            {
              title: 'Close Activity',
              url: 'close-activity',
              icon: 'document-text'
            },
            {
              title: 'Profile Update',
              url: 'profile-update-log',
              icon: 'document-text'
            },
            {
              title: 'Notification',
              url: 'notification',
              icon: 'notifications'
            },

          ]
        },
        // {
        //   title: 'Notification',
        //   url: 'notification',
        //   icon: 'notifications'
        // },

        /* {
          title: 'Profile Update Log',
          url: 'profile-update-log',
          icon: 'person'
        }, */



        // {
        //   title: 'New Patient',
        //   url: 'patient-form',
        //   icon: 'person',

        // },
        // {
        //   title: 'State',
        //   url: 'state',
        //   icon: 'clipboard',

        // },
        // {
        //   title: 'District',
        //   url: 'district',
        //   icon: 'clipboard',

        // },
        // {
        //   title: 'Clinics',
        //   url: 'clinics',
        //   icon: 'clipboard',

        // },
        // {
        //   title: 'Medicine',
        //   url: 'medicine-list',
        //   icon: 'medkit',

        // },
        // {
        //   title: 'Diagnosis List',
        //   url: 'diagnosis-list',
        //   icon: 'medkit',

        // },


        {
          title: 'Add Record',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Add Staff',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Doctors',
                  url: 'doctors',
                  icon: 'medkit',

                },
                {
                  title: 'Clinicians',
                  url: 'clinicians',
                  icon: 'clipboard',

                },
                {
                  title: 'MHW',
                  url: 'mhw',
                  icon: 'person',

                },
                {
                  title: 'SDO',
                  url: 'sdo',
                  icon: 'person',

                },
                {
                  title: 'Management',
                  url: 'mhw-reporter-profile',
                  icon: 'person',

                },
                {
                  title: 'Sub Admin',
                  url: 'subadmins',
                  icon: 'person',

                },
                {
                  title: 'Admin Staff',
                  url: 'office-admin-list',
                  icon: 'person',

                },
              ]
            },
            {
              title: 'Add Patient',
              url: 'patient-form',
              icon: 'list',

            },
            {
              title: 'Add Clinic',
              url: 'clinics',
              icon: 'list',

            },
            {
              title: 'Add Medicine',
              url: 'medicine-list',
              icon: 'list',

            },
            {
              title: 'Add Diagnosis',
              url: 'diagnosis-list',
              icon: 'list',

            },
            {
              title: 'Add District',
              url: 'district',
              icon: 'list',

            },
            {
              title: 'Add State',
              url: 'state',
              icon: 'list',

            },
            {
              title: 'Add Volunteer',
              url: 'volunteer-add',
              icon: 'list',

            },

          ]
        },

        {
          title: 'Clinic Data',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Todays Appointments',
              url: 'todays-appointment',
              icon: 'list'
            },
            {
              title: 'Search Appointments',
              url: 'appointment-list',
              icon: 'list',

            },
            {
              title: 'Patient list',
              url: 'patient-list',
              icon: 'list',

            },
            {
              title: 'Volunteer list',
              url: 'volunteer',
              icon: 'list',

            },
            {
              title: 'Follow Up Notes',
              url: 'notes-details',
              icon: 'list',

            },
            {
              title: 'SDO Activity Approval',
              url: 'sdo-activity-list',
              icon: 'document-text'
            },
          ]
        },

        {
          title: 'Activity Approvals',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'SDO Activity Approval',
              url: 'sdo-activity-list',
              icon: 'document-text'
            },
            {
              title: 'MHW Activity Approval',
              url: 'mhw-activity-list',
              icon: 'document-text'
            },
            {
              title: 'Prescription Approval',
              url: 'doctor-patient-list',
              icon: 'list'
            },
          ]
        },

        {
          title: 'Mapping',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Doctor to Clinic',
              url: 'assign-doctor-list',
              icon: 'list',

            },
            {
              title: 'Clinician to Clinic',
              url: 'assigned-roles-list/clinician',
              icon: 'list',
            },
            {
              title: 'MHW to Clinic',
              url: 'assigned-roles-list/mhw',
              icon: 'list',

            },
            {
              title: 'SDO to Clinic',
              url: 'assigned-roles-list/sdo',
              icon: 'list',

            },
            {
              title: 'Volunteer to Clinic',
              url: 'assign-volunteer-to-clinic',
              icon: 'list',

            },
            {
              title: 'Map MHW to Clinician',
              url: 'assign-mhw-list',
              icon: 'list',

            },
            {
              title: 'Manage Approver',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Manage Leave Approver',
                  url: 'leave-aprrover-manage',
                  icon: 'list',
                },
                {
                  title: 'Manage SDO Approver',
                  url: 'manage-sdo-approver',
                  icon: 'list',

                },
                {
                  title: 'Role Management',
                  url: '',
                  icon: 'list',
                  "submenu3": [
                    {
                      title: 'Change Role',
                      url: 'move-user',
                      icon: 'list',

                    },
                    {
                      title: 'Multi-Role User',
                      url: 'multiple-role-user',
                      icon: 'person',

                    },
                    {
                      title: ' Useful Links',
                      url: 'digital-features',
                      icon: 'link'
                    },
                  ]
                },

              ]
            },

          ]
        },

        {
          title: 'My Area',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Profile',
              url: 'profile',
              icon: 'person',
            },
            {
              title: 'Change Password',
              url: 'change-password',
              icon: 'key'
            },
            {
              title: 'Expense',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Expense Entry',
                  url: 'expenses',
                  icon: 'document-text'
                },
                {
                  title: 'Expense Report',
                  url: 'expense-report',
                  icon: 'list'
                },
              ]
            },
            {
              title: 'Leave',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Leave Approval',
                  url: 'leave-application',
                  icon: 'person'
                },
                {
                  title: 'Leave Application',
                  url: 'leave-form',
                  icon: 'list'
                },
                {
                  title: 'My Leave',
                  url: 'leave-list',
                  icon: 'list'
                },
              ]
            },
            {
              title: 'Make Request',
              url: '',
              icon: 'list',
              "submenu2": [

                {
                  title: 'Requests Made',
                  url: 'user-request-list',
                  icon: 'list'
                },
                {
                  title: 'Mcare',
                  url: '',
                  icon: 'list',
                  "submenu2": [
                    {
                      title: 'Functionality',
                      url: 'feedback',
                      icon: 'list'
                    },
                    {
                      title: 'Add Records',
                      url: 'add-records',
                      icon: 'list',
                    },
                    {
                      title: 'Delete Records',
                      url: 'delete-record',
                      icon: 'list',
                    },
                    {
                      title: 'Change Records',
                      url: 'change-records',
                      icon: 'list',
                    },


                  ],
                },

                {
                  title: 'Email',
                  url: 'outside-info',
                  icon: 'list'
                },
                {
                  title: 'Ideas',
                  url: 'ideas-list',
                  icon: 'list'
                },
              ]
            },
            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
          ]
        },


        // {
        //   title: 'Add Staff',
        //   url: '',
        //   icon: 'list',
        //   "submenu1": [
        //     {
        //       title: 'Doctors',
        //       url: 'doctors',
        //       icon: 'medkit',

        //     },
        //     {
        //       title: 'Clinicians',
        //       url: 'clinicians',
        //       icon: 'clipboard',

        //     },
        //     {
        //       title: 'MHW',
        //       url: 'mhw',
        //       icon: 'person',

        //     },
        //     {
        //       title: 'SDO',
        //       url: 'sdo',
        //       icon: 'person',

        //     },
        //     {
        //       title: 'Management',
        //       url: 'mhw-reporter-profile',
        //       icon: 'person',

        //     },
        //     {
        //       title: 'Sub Admin',
        //       url: 'subadmins',
        //       icon: 'person',

        //     },
        //     {
        //       title: 'Admin Staff',
        //       url: 'office-admin-list',
        //       icon: 'person',

        //     },
        //   ]
        // },


        // {
        //   title: 'Assign MHW To Clinician',
        //   url: 'assign-mhw-list',
        //   icon: 'list',

        // },
        // {
        //   title: 'Assign Volunteer',
        //   url: 'assign-volunteer',
        //   icon: 'newspaper'
        // },
        // {
        //   title: 'Patient list',
        //   url: 'patient-list',
        //   icon: 'list',

        // },



        // {
        //     title: 'Change Role',
        //     url: 'move-user',
        //     icon: 'list',

        //   },
        // {
        //   title: 'Schedule Appointment',
        //   url: 'schedule-list',
        //   icon: 'newspaper'
        // },
        // {
        //   title: 'Appointment Search',
        //   url: 'appointment-list',
        //   icon: 'list',

        // },
        // {
        //   title: 'Todays List',
        //   url: 'todays-appointment',
        //   icon: 'list'
        // },
        // {
        //   title: 'Follow Up Notes',
        //   url: 'notes-details',
        //   icon: 'list',

        // },

        // {
        //   title: 'Volunteer Appointment',
        //   url: 'volunteer-appointment',
        //   icon: 'newspaper',

        // },



        {
          title: 'Reports',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'Patient Report',
              url: 'patient-report',
              icon: 'person'
            },
            {
              title: 'Patient Deactivation',
              url: 'patient-activity-report',
              icon: 'person'
            },
            {
              title: 'Patient Year Report',
              url: 'year-count-report',
              icon: 'person'
            },
            {
              title: 'Patient Date Range Report',
              url: 'date-range-patient-report',
              icon: 'person'
            },
            {
              title: 'Patient Prescription Report',
              url: 'patient-prescription-report',
              icon: 'person'
            },
            {
              title: 'Staff Report',
              url: 'staff-reports',
              icon: 'people'
            },
            {
              title: 'Patient Diagnosis Report',
              url: 'patient-diagnosis-report',
              icon: 'person'
            },
            {
              title: 'Clinician Report',
              url: 'clinician-report',
              icon: 'newspaper',

            },
            {
              title: 'Clinician Daily Activity Report',
              url: 'clinician-doctor-activity-report/clinician',
              icon: 'newspaper',

            },
            {
              title: 'Doctor Daily Activity Report',
              url: 'clinician-doctor-activity-report/doctor',
              icon: 'newspaper',

            },
            {
              title: 'Staff Expense Report',
              url: 'expense-all-staff-report',
              icon: 'list',

            },
            {
              title: 'Appointments Report',
              url: 'appointment-report',
              icon: 'newspaper',

            },
            {
              title: 'Clinician Wise Patient Report',
              url: 'clinician-wise-patient-list',
              icon: 'newspaper',

            },
            {
              title: 'MHW Activity Report',
              url: 'mhw-activity-report',
              icon: 'person'
            },
            {
              title: 'SDO Activity Report',
              url: 'sdo-activity-report',
              icon: 'person'
            },
            {
              title: 'Clinic Report',
              url: 'clinic-report',
              icon: 'clipboard'
            },
            {
              title: 'Send Reminder Email',
              url: 'remainder-contribution',
              icon: 'clipboard'
            },
            {
              title: 'Location Based Attendance Report',
              url: 'login-tracking-report',
              icon: 'location'
            },
            {
              title: 'Leave Information Report',
              url: 'leave-report',
              icon: 'location'
            }
          ]
        },


      ];
    } if (role == 'office_admin') {
      this.appPages = [
        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Staff Report',
          url: 'staff-reports',
          icon: 'people'
        },
        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },



      ];
    }
    else if (role == 'sdo') {

      this.appPages = [
        {
          title: 'Home',
          url: 'sdo-activity',
          icon: 'home'
        },
        {
          title: 'Switch Clinic',
          url: 'switch-clinic',
          icon: 'toggle'
        },
        {
          title: 'Leave Menu',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Leave Approval',
              url: 'leave-application',
              icon: 'person'
            },
            {
              title: 'Leave Application',
              url: 'leave-form',
              icon: 'list'
            },
            {
              title: 'My Leave',
              url: 'leave-list',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Patient list',
          url: 'patient-list',
          icon: 'document-text'
        },
        {
          title: 'Patient Encounter',
          url: 'sdo-psi-entries',
          icon: 'document-text'
        },
        {
          title: 'Approval Status',
          url: 'sdo-notification',
          icon: 'notifications'
        },
        {
          title: 'Activity & Expense',
          url: '',
          icon: 'list',
          "submenu1": [
            {
              title: 'Activity Entry',
              url: 'sdo-activity',
              icon: 'document-text'
            },
            {
              title: 'Activity Report',
              url: 'sdo-activity-report-file',
              icon: 'list'
            },
            {
              title: 'Expense Entry',
              url: 'expenses',
              icon: 'document-text'
            },
            {
              title: 'Expense Report',
              url: 'expense-report',
              icon: 'list'
            },
          ]
        },
        {
          title: 'Staff Report',
          url: 'staff-reports',
          icon: 'people'
        },
        {
          title: 'Profile',
          url: 'profile',
          icon: 'person'
        },
        {
          title: 'Change Password',
          url: 'change-password',
          icon: 'key'
        },
        {
          title: 'Digital Features',
          url: 'digital-features',
          icon: 'link'
        },
        {
          title: 'Requests',
          url: '',
          icon: 'list',
          "submenu1": [

            {
              title: 'My Requests',
              url: 'user-request-list',
              icon: 'list'
            },
            {
              title: 'Mcare',
              url: '',
              icon: 'list',
              "submenu2": [
                {
                  title: 'Functionality',
                  url: 'feedback',
                  icon: 'list'
                },
                {
                  title: 'Add Records',
                  url: 'add-records',
                  icon: 'list',
                },
                {
                  title: 'Delete Records',
                  url: 'delete-record',
                  icon: 'list',
                },
                {
                  title: 'Change Records',
                  url: 'change-records',
                  icon: 'list',
                }
              ],
            },

            {
              title: 'Email',
              url: 'outside-info',
              icon: 'list'
            },
            {
              title: 'Ideas',
              url: 'ideas-list',
              icon: 'list'
            },
          ]
        },


      ];
    }
  }

  getClinicNameById(id) {
    var formData1: any = new FormData();
    formData1.append("key", "get_clinic_by_id_name");
    formData1.append("id", id);
    this.http.post(this.baseURI + 'manage-clinic.php', formData1).subscribe((res1: any) => {
      if (res1 != '') {
        this.clinic_name = res1.clinic_place_name;
      } else {
        this.clinic_name = '';
      }

    })
  }
  getCurrentCoordinates() {
    this.geolocation.getCurrentPosition().then((resp) => {
      console.log(resp)
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.getAddress(this.latitude, this.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }

  // get address using coordinates
  getAddress(lat, long) {
    //alert();
    this.nativeGeocoder.reverseGeocode(lat, long, this.nativeGeocoderOptions)
      .then((res: NativeGeocoderResult[]) => {
        this.address = this.pretifyAddress(res[0]);
        //alert(this.address);
      })
      .catch((error: any) => {
        //alert('Error getting location'+ JSON.stringify(error));
      });
  }

  // address
  pretifyAddress(addressObj) {
    //alert(JSON.stringify(addressObj));
    let obj = [];
    let address = "";
    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }
  logout() {

    this.clinic_name = '';
    this.showLogout = false;
    this.menuCntrl.enable(false);
    this.authGuardService.logout();
    //this.router.navigate(['/sign-in']);
  }
  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

}
